import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { config, useSpring, animated } from 'react-spring'
import scrollTo from 'gatsby-plugin-smoothscroll';
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import ASCTable from '../components/tableAnimation'
import Asclogo from '../components/ascAnimation'
import styled from 'styled-components'
import { AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'
import GridAbout from '../components/grid-about'
import '../styles/animations.css'

type PageProps = {
  data: {
    instagram: ChildImageSharp
    content: ChildImageSharp
  }
}

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100vh 100vh;

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100vh 100vh;
  }
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100vh 100vh;
  }
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 85vh 25vh 85vh 25vh;
  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 85vh 25vh 100vh 25vh;
  }

`

const PButton = styled(Button)`
  background: white;
  color: black;
  border: 1px solid black;
  max-width: 200px;
  margin: ${props => props.theme.space[7]} 0;
  :hover {
    background: black;
    color: white;
    transition: 0.6s ease 0s !important;
    cursor: pointer;

`

const AboutBox = styled(AnimatedBox)`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    > h1 {
      font-size: 5.5em;
      margin: 0;
    }
    > p {
      max-width: 50vw;
      text-align: justify;
      line-height: 1.25em;
    }
    > strong {
      max-width: 50vw;
    }
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      > h1 {
        font-size: 3.5em;
      }
      > p {
        max-width: 90vw;
      }
      > strong {
        max-width: 90vw;
      }
    }
    `

const ContentBox = styled(GridAbout)`
    box-sizing: border-box;
    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      > div {
          margin: auto;
          left: auto;
          max-width: 200px;
      }
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      > div {
          margin: auto;
          left: auto;
          max-width: 200px;
      }
    }

    `

const SecondBox = styled(AnimatedBox)`
    box-sizing: border-box;
    background-image: linear-gradient(to right bottom, #c5ffe4, #c5ffe4, #c5ffe4, #c5ffe4, #c5ffe4, #c3ffef, #c4fff8, #c8ffff, #d8feff, #eafdff, #f9fdff, #ffffff);
    > h2 {
      font-size: ${props => props.theme.fontSizes[3]};
    }
    > p {
      max-width: 60vw;
      text-align: justify; 
    }
    > hr {
      border: 0;
      height: 1px;
      background: #333;
      margin: 3vh 0;
      
    }
    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      > h1 {
        font-size: 3.5em;
      }
      > h2 {
        font-size: ${props => props.theme.fontSizes[2]};
      }
      > p {
        max-width: 90vw;
      }
    }
    `


const About: React.FunctionComponent<PageProps> = ({ data: { instagram, content } }) => {
  const pageAnimation = useSpring({
    config: config.slow, delay: 100,
    from: { opacity: 0 }, 
    to: { opacity: 1 },
  })
  const contentAnimation = useSpring({
    config: config.slow, delay: 300,
    from: { opacity: 0 }, 
    to: { opacity: 1 },
  })

    return (
      <Layout color="#c5ffe4">
        <SEO title="About | ASC Studio" desc="ASC Studio, Leading Dublin Creative Studio in Web Design, Brand Idenity, Interiors & Bespoke Furniture." />
        <Area>
        <GridAbout>
        <AboutBox style={pageAnimation} px={[6, 6, 8, 10]}>
        <h1>What we do.</h1>
        <p> We are a multi-displined Irish design studio specilising in built & digital design.
            Our scope encompases Commercial & Residential Interior Fit-Outs <em>including</em> Bespoke Furniture Design & Production.
            To Visual Identity, Graphic Design & Art/ Brand Direction <em>including</em> Web Design & Development</p>

        <strong>We help amazing people & businesses realise their ideas.</strong>

        <PButton onClick={() => scrollTo('#Content')} py={3} px={8}>More Info</PButton>
        </AboutBox>
        </GridAbout>
        
        <ContentBox>
          <ASCTable />
        </ContentBox>

        <GridAbout>
        <SecondBox id="Content" style={contentAnimation} py={[6, 6, 6, 8,]} px={[6, 6, 8, 10]}>
          <hr />
          <p>
            <strong>Our studio</strong> beleives strongly in the importance of good design and the impact it can have on how we interact physically and digitally. 
            How we interact with objects, our homes, or our workplaces. How we navigate websites, apps, printed media or device menus. 
            <strong> We make these interactions considered.</strong></p>
          
          <h2>We provide sustainable, quality driven design orintated solutions.</h2>
          <p>
            As we cover a wide scope we vary our teams to suit the specific project at hand.
            This enables us to realise a project on an individual and personal level,
            while still having the best people and scalability required to suit the needs of our largest clients.</p>
          <p>
            And provide the required complementary services in print, digital & social media - 
            copy, content, strategic planning - content photography and art direction. 
            </p>
            <p>
            <strong>We produce and supply a small range of <em><a href="/bespoke-irish-furniture-deisgn">interior furniture</a></em> & <em><a href="/irish-interior-giclee-prints">interior prints</a></em> for retail sale. </strong>
             A selection of our favorite prints are currently available from the asc <strong><a href="/store">ART STORE</a></strong>. 
          </p>
          <p>
            <p><strong>Check out our <a href="/projects">recent projects</a> to see what we've been up to.</strong></p>
     
            <strong>Starting something new?</strong><br />
            We'd love to here from you, <a href="/contact">contact us</a>. 
          </p>
          <hr />
          <Asclogo />
        </SecondBox>
        </GridAbout>
        
          <GridItem to="/instagram" aria-label="See our Instagram pictures">
            <Img fluid={instagram.childImageSharp.fluid} />
            <span>Instagram</span>
          </GridItem>

        </Area>
      </Layout>
    )
}

export default About

export const query = graphql`
    query About {
      instagram: file(sourceInstanceName: { eq: "images" }, name: { eq: "instagram" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content: file(sourceInstanceName: { eq: "images" }, name: { eq: "asc_table_002" }) {
        childImageSharp {
          fixed(quality: 95, width: 200) {
            ...GatsbyImageSharpFixed_tracedSVG
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `

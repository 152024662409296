import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(58, 55, 55, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(58, 55, 55, 1)"
  }
};


const Asclogo: React.FunctionComponent<PageProps> = () => {
    const [ref, inView] = useInView({
        threshold: 0,
      })
    

    return (
    <div className="logoContainer">
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 843.09 263.21"
      className="logo_animation"
      ref={ref} 
    >
        <motion.path
        d="M0,257.79c.53-1.51.81-2.47,1.19-3.39q48.48-117.32,97-234.63c1.71-4.13,3.46-8.25,5.13-12.4.46-1.13,1-1.95,2.36-1.94,5.32,0,10.64,0,16,.07a5,5,0,0,1,1.09.38c-.34.94-.65,1.87-1,2.77Q105.63,47.54,89.56,86.44l-66,159.7c-1.35,3.27-2.73,6.53-4.07,9.81-.41,1-.85,1.86-2.14,1.85Z"
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 2, ease: "linear" },
          fill: { duration: 2.1, ease: [1, 0, 0.8, 1] }
        }}
      />

        <motion.path
                d="M171.74,184.29H175c3.82,0,7.65,0,11.47,0,4.56,0,3.7-.61,5.45,3.55,3.58,8.51,7.06,17.05,10.59,25.58l16.89,40.81c.41,1,.72,2,1.21,3.43-1.31.06-2.21.14-3.11.14-4.2,0-8.4-.12-12.59.06-2.22.09-3.28-.7-4.13-2.76-7.76-19-15.63-37.89-23.47-56.84q-2.3-5.55-4.57-11.11C172.44,186.4,172.2,185.62,171.74,184.29Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                }}
            />

        <motion.path
                d="M131.57,40.66c16.78,40.9,33.56,81.8,50.5,123.11a27.43,27.43,0,0,1-2.9.34c-4.38,0-8.77-.09-13.15.06-2.13.07-3.09-.8-3.86-2.71-6.83-16.82-13.75-33.61-20.64-50.41q-9.37-22.87-18.79-45.74a6,6,0,0,1,0-5c2.41-5.65,4.67-11.37,7-17.05.35-.84.75-1.66,1.13-2.48Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                }}
            />

        <motion.path
                d="M434.69.85c-1,.21-2.07.46-3.12.62-14.09,2.11-27.09,6.87-38.31,15.84-11.67,9.34-19.44,21.24-21.68,36.08-3.17,21.08,2.43,39.74,17.2,55.35,10.64,11.25,23.86,18.41,38.32,23.38,7.84,2.7,15.87,4.82,23.75,7.39a106.73,106.73,0,0,1,25.57,11.8c7.55,4.93,13.86,11,17.77,19.31a50.7,50.7,0,0,1,4.47,18.92c.75,12.81-2.3,24.42-10.8,34.3a49.79,49.79,0,0,1-18.93,13.57,66.81,66.81,0,0,1-33.12,4.89,2.38,2.38,0,0,1-1.49-.7c2.07-.44,4.16-.84,6.21-1.35,10.85-2.67,20.42-7.6,28-16a43.35,43.35,0,0,0,11.23-28,58,58,0,0,0-2.56-21c-3.51-10.5-10.62-18-19.74-23.88-8.86-5.7-18.57-9.51-28.53-12.7-7.28-2.33-14.68-4.27-21.89-6.79-12.71-4.45-24.36-10.84-34.22-20.16C360.16,99.85,353,85.29,352,67.9c-1.41-25.26,9.77-43.79,31-56.64C393.45,4.94,405,1.68,417.14.62,422.05.19,427,.24,432,.11c.89,0,1.78.12,2.68.18Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2.2, ease: [1, 0, 0.8, 1] }
                }}
            />

        <motion.path
                d="M349.93,212.06c.71.49,1.18.77,1.6,1.11,4.16,3.29,8.24,6.71,12.52,9.84a3.32,3.32,0,0,1,1.54,4c-.47,1.9-.66,3.87-1.22,5.74a3.13,3.13,0,0,0,1.18,3.64A91.77,91.77,0,0,0,384,248.9a121.3,121.3,0,0,0,41.75,12.75,13.47,13.47,0,0,1,4.41,1.15c-1.57.14-3.13.38-4.7.4a126.71,126.71,0,0,1-54.84-11.33,101,101,0,0,1-22.91-14.38c-2-1.7-2.73-3.27-2.06-6,1.41-5.69,2.36-11.49,3.53-17.25A22.11,22.11,0,0,1,349.93,212.06Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                }}
            />

        <motion.path
                d="M488.46,31.65c-.85,3.22-1.6,6.07-2.48,9.39-14.73-9.77-30.36-16.37-47.49-19.26v-.57a15.15,15.15,0,0,1,1.87-.39,76.5,76.5,0,0,1,29.75,3.62c5.22,1.65,10.3,3.77,15.42,5.72C486.46,30.52,487.32,31.07,488.46,31.65Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                }}
            />

        <motion.path
                d="M767.35.76c-3.27.44-6.19.81-9.11,1.24-25.48,3.68-48.13,13.59-67.34,30.89-18.86,17-30.76,38.17-36.79,62.71a149,149,0,0,0-4.08,33c-.3,16.35,1.49,32.4,6.61,47.95,13,39.56,39.44,65.95,78.78,79.45a132.78,132.78,0,0,0,29.2,6.13c.68.07,1.36.21,2,.32l0,.59c-3,.08-6.07.27-9.11.21a131.43,131.43,0,0,1-42.24-7.31c-41.12-14.7-67.5-43.27-79.29-85.22-4.55-16.19-5.79-32.79-4.66-49.59s4.6-32.86,11.58-48.11c15.92-34.74,42.64-57,79.12-67.76A133.12,133.12,0,0,1,764.89.11c.56,0,1.11.08,1.67.15C766.7.28,766.83.42,767.35.76Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2.3, ease: [1, 0, 0.8, 1] }
                }}
            />

        <motion.path
                d="M773.28,21.52c1.37-.05,2.74-.13,4.1-.14,22.29-.12,43.47,4.48,63.31,14.84,1.73.91,2.5,2,2.39,3.9-.12,2.23,0,4.48,0,6.72,0,.8-.17,1.61-.32,3-20.78-16-43.79-25.17-69.55-27.36C773.2,22.16,773.24,21.84,773.28,21.52Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                }}
            />

        <motion.path
                d="M843,213.83c0,3.78,0,7.3,0,10.82,0,1.54-1.41,1.85-2.43,2.39a127,127,0,0,1-22.75,9.27,133.46,133.46,0,0,1-43.39,5.35,3.05,3.05,0,0,1-2-.66c1.3-.21,2.59-.48,3.9-.61a128,128,0,0,0,63.55-24.87c.68-.5,1.36-1,2-1.46A5.33,5.33,0,0,1,843,213.83Z"
                variants={icon}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                }}
            />
     </motion.svg> 
    </div>

    )
}
export default Asclogo



import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(0, 0, 0, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(0, 0, 0, 1)"
  }
};

const ASCTable: React.FunctionComponent<PageProps> = () => {
  const [ref, inView] = useInView({
      threshold: 0.25,
    })

return (
<div className="animationContainer">
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 412.72 520.33"
      className="about_animation"
      ref={ref} 
    >
      <motion.path
        d="M68.05,72.35l344.67-1.83V56.27H79.17l-1.38,0c-2.18.06-3.66.08-4.58.09-2.91,0-5.27.15-6.81.22-2,.09-3.52.18-4.66.26-3,.19-4.09.31-4.79.39a28.75,28.75,0,0,0-4,.65,21.29,21.29,0,0,0-3.06,1v.51l.68,14Z"
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 2, ease: "linear" },
          fill: { duration: 3, ease: [1, 0, 0.8, 1] }
        }}
      />

<motion.path
        d="M182.32,100.78l-2.55-2.28-1-1.32-.22-.87-.06-.41-.13-1-.05-1.45-.11-12.89,4.12-1.14v.34l.11,13,0,1.47.13,1.06.16,1,.37.79.57.72,1.46,1.43,1.27,1.13,83.1,78.19,4.27,4.1,2.51,2.42,2.3,2.48,2.11,2.54,1.09,1.44,1.83,2.88,1.23,2,1,1.81,1.4,3.26.93,2.52.67,2.08.55,1.9.58,2.34.85,4.29.46,4.37v6.82l-.43,3.77q-.56,2.73-1.13,5.47l-.4,1.66-.68,2.13-1.38,3.79L177.93,518.38l-3.06-21.67,103.38-263.2,1.82-5.55.65-2.47.27-1.56.34-2.37.2-1.88.19-2.27v-2.07l-.15-3.08-.26-2.68-.3-2.32-.58-3-.68-1.88-.94-2.89-1.15-3.11-1.38-3.07-2.75-4.73-1.4-2-1.48-1.91L269.47,183l-2.15-2.27-3.1-3-3.36-3.07Z"
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{ when: "afterChildren",
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 3, ease: [1, 0, 0.7, 1] }
        }}
      />

<motion.path
        d="M313.14,79h0l24.35,0h74.58v97.42H372.94l-.56.1-.1,0-.68.28-1,.5-1.4.82L368,179l0,0-1.19,1.07-.05.05L365.16,182l-.06.09-.83,1.61-.58.91a.25.25,0,0,0,0,.07L220.43,518l-42,1.82L288.39,242.15a56.66,56.66,0,0,0,2.85-28.4,55.17,55.17,0,0,0-5.16-15.82,53.08,53.08,0,0,0-8-11.65c-1.67-1.81-3.09-3.07-3.56-3.49-.07-.06-7.61-6.81-90.32-85.3a3.32,3.32,0,0,1-.84-1.15,3.67,3.67,0,0,1-.27-1.08,7.3,7.3,0,0,1,0-1.21,1.91,1.91,0,0,0,0-.59c-.08-.75-.31-7.26-.21-11.5l.05-2.26L313.14,79m-130.3.24h0m130.3-.74-130.8.74h0L182.29,82c-.12,4.47.14,11.68.23,11.68h0c.07,0-.05.85,0,1.69a4.33,4.33,0,0,0,.32,1.22,4,4,0,0,0,.94,1.32c83.8,79.52,90.34,85.31,90.34,85.31a47.2,47.2,0,0,1,3.52,3.45,51.88,51.88,0,0,1,7.94,11.54,54.38,54.38,0,0,1,5.12,15.67A56.1,56.1,0,0,1,287.92,242L177.71,520.33l43-1.86L364.11,184.91l.58-.91.86-1.65,1.54-1.84,1.19-1.06,1.25-.88,1.36-.8.94-.49.64-.26.56-.09h39.54V78.51H337.49l-24.35,0Z"
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 3, ease: [1, 0, 0.8, 1] }
        }}
      />

<motion.path
        d="M313.14,79h0l24.35,0h74.58v97"
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 2, ease: "linear" },
          fill: { duration: 3, ease: [1, 0, 0.8, 1] }
        }}
      />

<motion.path
        d=""
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] }
        }}
      />

<motion.path
        d=""
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 3, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] }
        }}
      />

<motion.path
        d=""
        variants={icon}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 3, ease: [1, 0, 0.8, 1] }
        }}
      />
    </motion.svg> 
  </div>

  )
}
export default ASCTable


